<template>
  <div class="data-box">
    <van-nav-bar
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    >
      <template #title>
        <span>{{showType === 0 ? '车辆对接' : (showStatus === 0 ? '车辆签到' : '车辆签退')}}</span>
      </template>
    </van-nav-bar>
    <div class="data-box-body" v-show="showType===0">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">负责人</span>
          <span class="span-name align-right">{{ carForm.personInCharge ? carForm.personInCharge.split(' - ')[0] : "无" }}</span>
          <a :href="'tel:' + carForm.personInCharge.split(' - ')[1]" class="iconfont icon-dianhua1" v-show="carForm.personInCharge.split(' - ')[1]"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" v-for="(item,index) in keyValueList" :key="index">
          <span class="span-label">{{ item.key }}</span>
          <span class="span-value">{{ item.value ? item.value : "无" }}</span>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-table-us">
          <dl>
            <tr>
              <dt>里程数图片</dt>
              <dt>油电量图片</dt>
              <dt>车辆图片</dt>
            </tr>
            <tr>
              <dd v-for="(item,index) in imageList" :key="index" @click="showImagePreview(index)">
                <van-image class="image" fit="cover" round :src="item"></van-image>
              </dd>
            </tr>
            <tr>
              <dt>检查项</dt>
              <dt>检查结果</dt>
            </tr>
            <tr v-for="item in checkItemList" :key="item.id" v-show="item.isShow">
              <dd>{{item.name}}</dd>
              <dd>
                <span v-show="showStatus === 0">一</span>
                <i v-show="showStatus !== 0" :class="['iconfont', item.isCheck ? 'icon-tongguo' : 'icon-weitongguo']" :style="{color: (item.isCheck ? '#5ABB2B' : '#FF4A41')}"></i>
              </dd>
            </tr>
          </dl>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-label">其他备注</span>
          <span class="span-value">{{ carForm.note ? carForm.note : "无" }}</span>
        </div>
      </div>
    </div>
    <div class="data-box-body" v-show="showType===1">
      <div class="body-div">
        <div class="body-div-item" v-show="showStatus === 0">
          <div class="span-key">检查要求</div>
          <div class="span-value">{{ keyValueList[0].value ? keyValueList[0].value : "无" }}</div>
        </div>
        <div class="body-div-item">
          <div class="span-key">{{showStatus === 0 ? '抵达公里数' : '离场公里数'}}</div>
          <div class="span-input">
            <van-field
                v-model="carForm.personFoodMileNum"
                placeholder="车辆当前公里数"
                type="number"
                @input="inputHandler1"
            >
              <template #extra>
                <span>km</span>
              </template>
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">油电量剩余</div>
          <div class="span-input">
            <van-field
                v-model="carForm.idResidueSite"
                placeholder="车辆当前剩余油电量"
                type="digit"
                @input="inputHandler2"
            >
              <template #extra>
                <span>%</span>
              </template>
            </van-field>
          </div>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-table-us">
          <dl>
            <tr>
              <dt>里程数图片</dt>
              <dt>油电量图片</dt>
              <dt>车辆图片</dt>
            </tr>
            <tr>
              <dd>
                <van-uploader name="src1" v-model="fileList1" :after-read="afterRead" max-count="1" accept="image/*" @delete="deleteImage">
                  <template>
                    <div class="upload-div">
                      <i class="iconfont icon-plus-round"></i>
                    </div>
                  </template>
                </van-uploader>
              </dd>
              <dd>
                <van-uploader name="src2" v-model="fileList2" :after-read="afterRead" max-count="1" accept="image/*" @delete="deleteImage">
                  <template>
                    <div class="upload-div">
                      <i class="iconfont icon-plus-round"></i>
                    </div>
                  </template>
                </van-uploader>
              </dd>
              <dd>
                <van-uploader name="src3" v-model="fileList3" :after-read="afterRead" max-count="1" accept="image/*" @delete="deleteImage">
                  <template>
                    <div class="upload-div">
                      <i class="iconfont icon-plus-round"></i>
                    </div>
                  </template>
                </van-uploader>
              </dd>
            </tr>
            <tr v-show="showStatus === 0">
              <dt>检查项</dt>
              <dt @click="changeAllItem">
                <i :class="[isCheckedAll ? 'icon-border-checked-yes item-check' : 'icon-border-checked-no', 'iconfont']"></i>
              </dt>
            </tr>
            <tr v-for="item in checkItemList" :key="item.id" v-show="showStatus === 0 && item.isShow">
              <dd>{{item.name}}</dd>
              <dd @click="changeItem(item)">
                <i :class="[item.isCheck ? 'icon-border-checked-yes item-check' : 'icon-border-checked-no', 'iconfont']"></i>
              </dd>
            </tr>
          </dl>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">其他备注</div>
          <div class="span-input">
            <van-field
                v-model="carForm.note"
                rows="3"
                type="textarea"
                maxlength="300"
                placeholder="请输入车辆签到其他备注说明"
                show-word-limit
            >
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-foot" v-show="showStatus !== 2">
      <van-button round block type="info" @click="carSignIn">
        {{showType === 0 ? (showStatus === 0 ? '车辆签到' : '车辆签退') : (showStatus === 0 ? '确认签到' : '确认签退')}}
      </van-button>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import upload from "@/assets/common/upload";
import {ImagePreview} from "vant";
import {
  activityObjSignPage,
  personCarSign
} from "@/api/index";

export default {
  name: "orgCheck_car",
  data() {
    return {
      showType: 0,//0：查看，1：编辑
      showStatus: 0,//0: 未签到；1：已签到；2：已签退
      imageList: [
        require('../../assets/image/null.png'),
        require('../../assets/image/null.png'),
        require('../../assets/image/null.png')
      ],
      carForm: {
        "activityId": 0,
        "checkItem": "",
        "idResidueSite": "",
        "personFoodMileNum": "",
        "note": "",
        "picture1": "",
        "picture2": "",
        "picture3": "",
        "requireNote": "",
        "statusSendId": 0,
        "personInCharge": "",
        "signInPerson": "",
        "isInform": false,
        "type": 1
      },
      carFromTemp: {},//数据保存容器
      keyValueList: [
        { key: "检查要求", value: "" },
        { key: "抵达公里数", value: "" },
        { key: "油电量剩余", value: "" }
      ],
      isCheckedAll: false,
      checkItemList: [
        {id: 0, isCheck: false, name: "车辆外观", isShow: false},
        {id: 1, isCheck: false, name: "车辆内饰", isShow: false},
        {id: 2, isCheck: false, name: "车况良好", isShow: false},
        {id: 3, isCheck: false, name: "空调正常", isShow: false},
        {id: 4, isCheck: false, name: "皮革座椅", isShow: false},
        {id: 5, isCheck: false, name: "车厢异味", isShow: false},
        {id: 6, isCheck: false, name: "车内地毯", isShow: false},
        {id: 7, isCheck: false, name: "尾箱清空", isShow: false},
        {id: 8, isCheck: false, name: "油电剩余", isShow: false},
        {id: 9, isCheck: false, name: "里程表数", isShow: false},
        {id: 10, isCheck: false, name: "系统登记", isShow: false},
        {id: 11, isCheck: false, name: "车辆牌照", isShow: false}
      ],
      fileList1: [],
      fileList2: [],
      fileList3: [],
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getSelectOPDetails.activitySets;
      let activitySetsMain = that.$store.getters.getOPDetails.activitySets;
      that.getCarInfo(data => {
        if(data){
          that.carForm = data;
          that.showStatus = that.carForm.statusSendId;
          that.carForm.activityId = that.$store.getters.getSelectOPDetails.id;
          that.keyValueList[0].value = that.carForm.requireNote;
          that.keyValueList[1].value = that.carForm.personFoodMileNum ? (that.carForm.personFoodMileNum + 'km') : '';
          that.keyValueList[2].value = that.carForm.idResidueSite ? (that.carForm.idResidueSite + '%') : "";
          activitySets.forEach(item => {
            if(item.relateType === 1){
              item.checkTypeNumArticle.split(",").forEach(item => {
                that.checkItemList[item].isShow = true;
              })
            }
          })
          let checkItemList = that.carForm.checkItem.split(",");
          for(let i in checkItemList){
            that.checkItemList[i].isShow = checkItemList[i] == 0 ? false : true;
            that.checkItemList[i].isCheck = checkItemList[i] == 1 ? true : false;
          }
          for(let [key, value] of that.imageList.entries()) {
            let imageObj = {url: that.carForm['picture' + (key + 1)]};
            that['fileList' + (key + 1)][0] = imageObj;
            that.imageList[key] = that.carForm['picture' + (key + 1)];
          }
        }else{
          that.carForm.activityId = that.$store.getters.getSelectOPDetails.id;
          activitySetsMain.forEach(item => {
            if(item.relateType === 14){
              that.carForm.signInPerson = item.chargeName + " - " + item.chargePhone;
            }
          })
          activitySets.forEach(item => {
            if(item.relateType === 1){
              that.carForm.personInCharge = item.chargeName + " - " + item.chargePhone;
              that.keyValueList[0].value = item.noteNameDateStop;
              item.checkTypeNumArticle.split(",").forEach(item => {
                that.checkItemList[item].isShow = true;
              })
            }
          })
        }
        //that.carFromTemp = Object.assign({}, that.carForm);
      })
    },
    getCarInfo(callBack){
      let that = this;
      let params = {
        ayId: that.$store.getters.getSelectOPDetails.id,
        type: 1
      }
      activityObjSignPage(params).then(res => {
        that.$tool.resValidation(res, () => {
          if(typeof callBack == "function") callBack(res.data);
        })
      })
    },
    onClickLeft() {
      let that = this;
      if(that.showType === 1){
        that.showType = 0;
        //that.carForm = Object.assign({}, that.carFromTemp);
      }else{
        that.$router.go(-1);
      }
    },
    showImagePreview(index){
      let that = this;
      ImagePreview({
        images: that.imageList,
        startPosition: index,
      });
    },
    changeAllItem(){
      let that = this;
      that.isCheckedAll = !that.isCheckedAll;
      that.checkItemList.forEach(item => {
        item.isCheck = that.isCheckedAll;
      })
    },
    changeItem(item){
      item.isCheck = !item.isCheck;
    },
    //图片上传
    afterRead(file,detail){
      let that = this;
      upload.uploadFile(file,res => {
        if(detail.name === "src1"){
          that.fileList1[0].url = res.url;
          that.carForm.picture1 = res.url;
        }else if(detail.name === "src2"){
          that.fileList2[0].url = res.url;
          that.carForm.picture2 = res.url;
        }else if(detail.name === "src3"){
          that.fileList3[0].url = res.url;
          that.carForm.picture3 = res.url;
        }
      },err => {});
    },
    //删除图片
    deleteImage(file,detail){
      let that = this;
      if(detail.name === "src1"){
        that.carForm.picture1 = "";
      }else if(detail.name === "src2"){
        that.carForm.picture2 = "";
      }else if(detail.name === "src3"){
        that.carForm.picture3 = "";
      }
    },
    //人员签到、签退
    carSignIn(){
      let that = this;
      if(that.showType === 0 && that.showStatus === 0){
        let isHead = that.$tool.identityValidation(that.$store.getters.getOPDetails.id,1);
        if(isHead){
          that.showType = 1;
        }else{
          that.$dialog.confirm({
            message: '您不是该环节的负责人，确认代替执行么？',
          }).then(() => {
            that.showType = 1;
          }).catch(() => {
            // on cancel
          })
        }
      }else if(that.showType === 1 && that.showStatus === 0){
        that.dataValidation(() => {
          that.$dialog.confirm({
            message: '确定进行签到吗？',
          }).then(() => {
            let array = [];
            that.checkItemList.forEach(item => {
              array.push(item.isShow ? (item.isCheck ? 1 : 2) : 0);
            })
            that.carForm.checkItem = array.join(",");
            that.carForm.statusSendId = 1;
            delete that.carForm.id;
            personCarSign(that.carForm).then(res => {
              that.$tool.resValidation(res, () => {
                that.getCarInfo(data => {
                  that.initData();
                  that.showType = 0;
                  that.showStatus = 1;
                  that.$tool.updateSOPDSetsStatus(1, 1);
                  that.$toast.success('签到成功');
                })
              })
            })
          }).catch(() => {
            // on cancel
          })
        })
      }else if(that.showType === 0 && that.showStatus === 1){
        that.showType = 1;
      }else if(that.showType === 1 && that.showStatus === 1){
        that.dataValidation(() => {
          that.$dialog.confirm({
            message: '确定进行签退吗？',
          }).then(() => {
            that.carForm.statusSendId = 2;
            delete that.carForm.id;
            personCarSign(that.carForm).then(res => {
              that.$tool.resValidation(res, () => {
                that.getCarInfo(data => {
                  that.initData();
                  that.showType = 0;
                  that.showStatus = 2;
                  that.$tool.updateSOPDSetsStatus(1, 2);
                  that.$toast.success('签退成功');
                })
              })
            })
          }).catch(() => {
            // on cancel
          })
        })
      }
    },
    //数据验证
    dataValidation(callBack){
      let that = this;
      if(that.carForm.idResidueSite === ""){
        that.$toast('请输入油电量剩余！');
        return;
      }
      if(that.carForm.personFoodMileNum === ""){
        that.$toast('请输入抵达公里数！');
        return;
      }
      if(that.carForm.picture1 === ""){
        that.$toast('请上传里程数图片!');
        return;
      }
      if(that.carForm.picture2 === ""){
        that.$toast('请上传油电量图片!');
        return;
      }
      if(that.carForm.picture3 === ""){
        that.$toast('请上传车辆图片!');
        return;
      }
      /*let isCheckAll = true;
      that.checkItemList.forEach(item => {
        if(item.isShow){
          if(!item.isCheck){
            isCheckAll = false;
          }
        }
      })
      if(!isCheckAll){
        that.$toast('活动管理者对以下车辆信息有要求，请检查并勾选各个检查项!');
        return;
      }*/
      if(typeof callBack == "function") callBack();
    },
    //抵达公里数输入验证
    inputHandler1(){
      let that = this;
      let reg = /^(([0-9]+)|([0-9]+\.)|([0-9]+\.[0-9]{1,2}))$/;
      that.carForm.personFoodMileNum = reg.test(that.carForm.personFoodMileNum) ? that.carForm.personFoodMileNum : "";
    },
    //油电量剩余输入验证
    inputHandler2(){
      let that = this;
      that.carForm.idResidueSite = that.carForm.idResidueSite > 100 ? 100 : that.carForm.idResidueSite;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.item-check{
  color: #3476FF;
}
.body-div-table-us{
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #C1D5FF;
  font-size: 12px;
  dl{
    border-radius: 10px;
    margin: 0;
    tr{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      dt {
        width: 33%;
        height: 40px;
        line-height: 40px;
        margin: 0;
        text-align: center;
        background-color: #f0f5ff;
        color: #8B8FAD;
        border-right: 1px dotted #C1D5FF;
      }
      dt:first-child{
        width: 67%;
        border-top-left-radius: 10px;
      }
      dt:last-child{
        border-top-right-radius: 10px;
        border-right: 0;
      }
      dd {
        width: 33%;
        height: 40px;
        line-height: 40px;
        margin: 0;
        text-align: center;
        border-right: 1px dotted #C1D5FF;
        border-top: 1px dotted #C1D5FF;
      }
      dd:first-child{
        width: 67%;
      }
      dd:last-child{
        border-right: 0;
      }
    }
    tr:nth-child(1){
      dt:first-child{
        width: 34%;
      }
    }
    tr:nth-child(2){
      dd{
        height: 100px;
        .image{
          width: 80px;
          height: 80px;
          margin: 10px 0;
          border-radius: 10px;
        }
      }
      dd:first-child{
        width: 34%;
      }
    }
    tr:nth-child(3){
      dt:first-child{
        border-top: 1px dotted #C1D5FF;
        border-top-left-radius: 0;
      }
      dt:last-child{
        border-top: 1px dotted #C1D5FF;
        border-top-right-radius: 0;
      }
    }
  }
}
.upload-div{
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin: 10px 0;
  background-color: #F6F9FF;
  border-radius: 10px;
  i{
    color: #D6E3FE;
    font-size: 24px;
  }
}
::v-deep .van-uploader__preview {
  position: relative;
  margin: 10px 0px;
}
</style>